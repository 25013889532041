export default function testimonialReadMores(){
	let rmTriggers = document.querySelectorAll('.readmore-trigger');

	rmTriggers.forEach(function(el, index){
		let cb = el.closest('.review-block');

		if(cb){
			el.addEventListener('click', function(){
				cb.classList.toggle('active')
			});
		}
	});
}