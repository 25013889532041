import $ from 'jquery'
// import 'jquery.easing'

export default function multistep() {
	window.setFormHeight = function(form) {
		form = $(form);

		form.closest('form').css('height', ''); // reset
	
		var elementHeights = form.map(function () {
			return $(this).innerHeight();
		}).get();
	
		var maxHeight = Math.max.apply(null, elementHeights);

		maxHeight += $('.progressbar').outerHeight();

		// Set each height to the max height
		form.closest('form').height(maxHeight);
	}


	//jQuery time
	if ($('.questionnaire-form').length) {
		
		$('.questionnaire-form').each(function(){	
			
			// Check which CRM is used
			var form = $('fieldset', this);
			var q_form = this;


			// on load, set the next step first question to active
			var allQuestions = $(q_form).find('.form-group');
			// get the query params
			const urlParams = new URLSearchParams(window.location.search);

			// STEP1 COMPLETE: if step 1 is complete, set question 5 to active 
			const step1complete = urlParams.get('step1');
			const questionnaireComplete = urlParams.get('step2');
			const qualified = urlParams.get('q');

			if(step1complete == 'true') {
				var skipToStepIndex = 5;
				// populate the existing fields here
				const name = urlParams.get('name');
				const email = urlParams.get('email');
				// const phone = urlParams.get('phone');
				$('#inf_field_FirstName').val(name);
				$('#inf_field_Email').val(email);
				// $('#inf_field_Phone1').val(phone);

				// set the next field to active
				for (var i = 0, fieldset; (fieldset = allQuestions[i++]); ) {
					fieldset.style.opacity = 0;
					fieldset.style.pointerEvents = 'none';
				}
				allQuestions[0].classList.remove('active');
				allQuestions[3].classList.add('active');
				allQuestions[3].style.opacity = 1;
				allQuestions[3].style.visibility = 'visible';
				allQuestions[3].style.pointerEvents = 'all';

				// make the progress bar set the proper step counter to visible
				var progressBar = $('.progressbar li');
				for (var i = 0, progress; i < skipToStepIndex; (progress = progressBar[i++]) ) {
					if(progress) {
						progress.classList.add('active');
					}
				}
			}

			// QUESTIONNAIRE COMPLETE: if questionnaire is complete, set question 5 to active
			if(questionnaireComplete == 'true') {				
				for(var i = 0, fieldset; fieldset = allQuestions[i++];){
					fieldset.style.opacity = 0;
					fieldset.style.pointerEvents = 'none'
				}
				allQuestions[0].classList.remove('active');

				// If they are not qualified, send them to question 17. if not, send to 16
				console.log(qualified);
				if(qualified == 0) {
					allQuestions[15].classList.add('active');
					allQuestions[15].style.opacity = 1
					allQuestions[15].style.visibility = 'visible'
					allQuestions[15].style.pointerEvents = 'all'
				} else {
					allQuestions[14].classList.add('active');
					allQuestions[14].style.opacity = 1;
					allQuestions[14].style.visibility = 'visible';
					allQuestions[14].style.pointerEvents = 'all';
				}
				// set the proper step counter to visible
				var skipToStepIndex = 12;

				var progressBar = $('.progressbar li');
				for (var i = 0, progress; i < skipToStepIndex; (progress = progressBar[i++]) ) {
					if(progress) {
						progress.classList.add('active');
					}
				}
			}

			/* Make the outer wrapper the same height as the tallest field. */
			// Get an array of all element heights
			
			var resizeTimerForms;
			$(window).on('resize load', function () {
				clearTimeout(resizeTimerForms);
				resizeTimerForms = setTimeout(function () {
					setFormHeight(form);
				});
			});

			setFormHeight(form);

			function replaceNames() {
				var name = ' ' + $('input[name="inf_field_FirstName"]').val()
				$('.your-name').text(name)
			}
			// Replace the name fields with the entered name on enter
			$('input[name="inf_field_FirstName"]').on('change', function (el) {
				replaceNames()
			})
			// And on load
			$(document).ready(function () {
				replaceNames()
			})

			// Add a last class to the last progressbar item
			addClassToProgressBar();
			
			// multistep form code
			var current_fs, next_fs, previous_fs; //fieldsets
			var left, opacity, scale; //fieldset properties which we will animate
			var checkboxChecked = false, radioChecked = false, textInput = false, phoneInput = false, noInputs = false;
			var thisPhoneEl = false;
			var phoneValid = true;

			// With radio buttons, no need of next button
			$("form input[type=radio]").click(function() { 
				//Find nearest "next" button and activate it
				$(this).closest(form).find('input.next').click();
				$(this).closest(form).find('input._submit').click();
			});

			let allFS = document.querySelectorAll('fieldset');

			allFS.forEach(function(el, index){
				el.dataset.step = index;
			});

			var processingClick = false;
			$(".next, ._submit", q_form).click(function(e){
				e.preventDefault();

				if(processingClick){
					return;
				}

				processingClick = true;
				
				current_fs = $(this).closest('.form-group');

				var closestFieldset = $(this).closest('fieldset');

				if(closestFieldset.length){
					dataLayer.push({
						'event':'gtm.questionnaireProgress',
						'step':closestFieldset.data('step')
					});
				}

				/* validation - if fails display an error message. */
				checkboxChecked = current_fs.find('input[type=checkbox]').is(":checked");
				radioChecked = current_fs.find('input[type=radio]').is(":checked");
				textInput = false;
				phoneInput = false;
				noInputs = false;

				if (current_fs.find('input[type=text]').val() || current_fs.find('input[type=email]').val() || current_fs.find('input[type=tel]').val() || current_fs.find('textarea').val() || current_fs.find('select').val()) { 
					textInput = true 
				}

				if(current_fs.find('input.not-required, textarea.not-required').length > 0) {
					textInput = true;
				}

				thisPhoneEl = current_fs.find('input[name*="Phone"]');
				phoneValid = true;
				if(thisPhoneEl.length){
					phoneValid = _validatePhone(thisPhoneEl, false);
				}

				var thisEmail = current_fs.find('input[type=email]').val();
				if(textInput && thisEmail){
					textInput = _validateEmail(thisEmail);
				}

				var inputCount = current_fs.find('input, textarea, button');
				if (inputCount.length == 1) {
					noInputs = true
				}

				if (checkboxChecked == false && radioChecked == false && textInput == false && noInputs == false)
				{
					Sweetalert2.fire({
						title: "Something went wrong!",
						text: 'Please provide an answer to this question before moving on...',
						icon: 'error',
						showConfirmButton: false,
						timer: 5000,
					})
					processingClick = false;
				}
				else if(phoneValid == false){
					Sweetalert2.fire({
						title: "Something went wrong!",
						text: 'Please enter a valid phone number',
						icon: 'error',
						showConfirmButton: false,
						timer: 5000,
					})
					processingClick = false;
				}
				else {

					if(!$(this).hasClass('_submit')){
						
						// show the previous button now
						$('.previous').attr('disabled', false)
						
						// add the skip class if they have selected a conditional statement
						// if they run their own business, set questions 6,7,8 to skip
						if($('#inf_custom_Doyourunyourownbusiness_No').is(':checked')) {
							allQuestions[4].classList.add('skip');
							allQuestions[5].classList.add('skip');
							allQuestions[6].classList.add('skip');
							allQuestions[7].classList.remove('skip');
							allQuestions[8].classList.remove('skip');
							allQuestions[9].classList.remove('skip');
						}
						// if they don't run their own business, set the next 9,10,11 questions to skip
						if($('#inf_custom_Doyourunyourownbusiness_Yes').is(':checked')) {
							allQuestions[4].classList.remove('skip')
							allQuestions[5].classList.remove('skip')
							allQuestions[6].classList.remove('skip')
							allQuestions[7].classList.add('skip');
							allQuestions[8].classList.add('skip');
							allQuestions[9].classList.add('skip');
						}

						// if they can't invest in themselves, send to disqualified booking (skip question 16)
						if($('#inf_custom_Areyouabletoinvestinyourself4').is(':checked')) {
							allQuestions[14].classList.add('skip');
						}
						
						
						// set the next fieldset after the skip classes have been applied
						next_fs = current_fs.nextAll('.form-group').not('.skip').first();


						//activate next step on progressbar using the index of next_fs
						$('.progressbar li.active').next('li').addClass('active');
						next_fs.css('visibility','visible'); 

						
						//hide the current fieldset with style
						current_fs.animate({opacity: 0}, {
							step: function(now, mx) {
								//1. scale current_fs down to 80%
								scale = 1 - (1 - now) * 0.2;
								//2. bring next_fs from the right(50%)
								left = (now * 50)+"%";
								//3. increase opacity of next_fs to 1 as it moves in
								opacity = 1 - now;
								
								current_fs.css({
									'transform': 'scale('+scale+')', 
									'pointer-events': 'none',
									'visibility': 'hidden'
								});
								next_fs.css({ 
									'left': left, 
									'opacity': opacity, 
									'pointer-events': 'all',
									'visibility': 'visible'
								});
							}, 
							duration: 1000,

							//this comes from the custom easing plugin
							easing: 'easeInOutBack', 

							complete: function(){
								processingClick = false;
							}
						});
						current_fs.removeClass('active');
						next_fs.addClass('active');
						addClassToProgressBar()
					}else{
						// if this is the step 1 submit, check the step one radio button
						if($(e.target).hasClass('step-1')) {
							$('#step_1').prop("checked", true);

							dataLayer.push({
								event: 'gtm.stepOneSubmit'
							});

							// window.location.href="http://localhost:3000/questionnaire/?step1=true"
						}
						// if this is the step 2 submit, check the questionnaire completed radio button
						if($(e.target).hasClass('step-2')) {
							$('#step_2').prop('checked', true);

							dataLayer.push({
								event: 'gtm.stepTwoSubmit'
							});

							// send to lead library manually in step 2 for some reason
							if(typeof _sendToLeadLibrary == "function"){
								_sendToLeadLibrary(q_form);
							}

							// window.location.href="http://localhost:3000/questionnaire/?step2=true"
						}
						// alert('form submitted!');
						q_form.submit();
					}
				}

			});

			$(".previous").click(function(){
				if(processingClick){
					return;
				}
				
				processingClick = true;
				
				var current_fs = $(q_form).find('.form-group.active');
				previous_fs = current_fs.prevAll('.form-group').not('.skip').first();

				// If we've moved back to the first slide, disable the back button
				if (previous_fs.prevObject.length == 1) {
					$('.previous').attr('disabled', true)
				}
				// if the current slide is the first slide, abort mission
				if (previous_fs.length == 0) {
					return;
				}
				
				//de-activate current step on progressbar & show previous step
				var activeStep = $('.progressbar li.active');
				if(activeStep[activeStep.length - 1]) {
					activeStep[activeStep.length - 1].classList.remove('active');
				} 
				next_fs.css('visibility', 'visible'); 

				//hide the current fieldset with style
				current_fs.animate({opacity: 0}, {
					step: function(now, mx) {
						//1. scale previous_fs from 80% to 100%
						scale = 0.8 + (1 - now) * 0.2;
						//2. take current_fs to the right(50%) - from 0%
						left = ((1-now) * 50)+"%";
						//3. increase opacity of previous_fs to 1 as it moves in
						opacity = 1 - now;
						
						current_fs.css({
							'left': left,
							'pointer-events': 'none',
							'visibility': 'hidden'
						});
						previous_fs.css({
							'transform': 'scale('+scale+')', 
							'opacity': opacity,
							'pointer-events': 'all',
							'visibility': 'visible'
						});
					}, 
					duration: 1000, 
					
					//this comes from the custom easing plugin
					easing: 'easeInOutBack',

					complete: function(){
						processingClick = false;
					}	
				});
				current_fs.removeClass('active')
				previous_fs.addClass('active')
				addClassToProgressBar();
			});

			// stop enter key submitting form
			document.addEventListener('keydown', function(e) {
			    if (e.which == '13') {
			      e.preventDefault();
			    }
			});

			function _validateEmail(emailVal){
				var reg = new RegExp(/.*@.*\..*/);
				return reg.test(emailVal);
			}	

			function _validatePhone(phoneInput, submitOnly){
				// remove this line if you want to validate phone numbers
				// return true;

				var phoneVal = phoneInput.val();
				var reg = new RegExp(/^[0-9\s-+()]*$/m);	

				if(submitOnly){
					Sweetalert2.fire({
						title: "Something went wrong!",
						text: 'Please enter a valid phone number',
						icon: 'error',
						showConfirmButton: false,
						timer: 5000,
					})
					processingClick = false;
				}

				return reg.test(phoneVal);
			}

			function addClassToProgressBar() {
				var progressDots = $('.progressbar li');
				var lastActiveProgress = $('.progressbar li.active').last()
				progressDots.removeClass('last');
				lastActiveProgress.addClass('last');
			}
		});
	}
}
