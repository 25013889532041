export default function autofill() {
    (function($) {
        $(window).on('load', function () {
          // Listen for the name and email fields to be filled and store their values in localstorage.
          $('input[name="inf_field_FirstName"], input[name="inf_field_Email"]').on('input', function(el) {
            var fieldName = el.target.getAttribute('name');
            var inputValue = $(this).val();
            localStorage.setItem('kk_'+fieldName, inputValue)
          })

          // On load check if there are any saved fields of the same name and fill them in.
          var name = localStorage.getItem('kk_inf_field_FirstName');
          var email = localStorage.getItem('kk_inf_field_Email');
          // Populate any calendly urls with the name and email if they exist
          var calendlyIframe = $('iframe[src^="https://calendly.com"]');

          // If a localstorage item exists, prepopulate those fields on the current page
          if(name) {
            // populate any fields
            $('input[name="inf_field_FirstName"]').val(name);
            // populate calendly srcs
            calendlyIframe.attr('src', $(calendlyIframe).attr('src')+'&name='+name)
          }
          if(email) {
            // populate any fields
            $('input[name="inf_field_Email"]').val(email)
            // populate calendly srcs
            calendlyIframe.attr('src', $(calendlyIframe).attr('src')+'&email='+email)
          }
          

          // If the values are stored in the url, populate any of those fields on load and overwrite the saved ones
          var urlParams = new URLSearchParams(window.location.search);
          // Get the query params
          var queryName = urlParams.get('name');
          var queryEmail = urlParams.get('email');

          if(queryName) {
            // populate any fields
            $('input[name="inf_field_FirstName"]').val(queryName)
            // populate calendly srcs
            calendlyIframe.attr('src', $(calendlyIframe).attr('src') + '&name=' + queryName)
          }
          if(queryEmail) {
            // populate any fields
            $('input[name="inf_field_Email"]').val(queryEmail)
            // populate calendly srcs
            calendlyIframe.attr('src', $(calendlyIframe).attr('src') + '&email=' + queryEmail)
          }

        })
    })( jQuery );
}

